<template>
  <page-header-wrapper>
    <template slot="extra">
      <a-button type="primary" @click="add"  v-if="$auth('downloadList.add')">新增</a-button>
    </template>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper"></div>

      <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData">
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="gstitle" slot-scope="gstitle,record">
          <span @click="goUrl(record.link_url)" style="cursor:pointer">{{gstitle}}</span>
        </span>
        <span slot="time" slot-scope="create_time">
          {{create_time | time}}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="edit(record)" v-if="$auth('downloadList.edit')">编辑</a>
            <a @click="handleDel(record)" style="margin-left: 10px;" v-if="$auth('downloadList.del')">删除</a>
            <a @click="toView(record)" style="margin-left: 10px;" v-if="$auth('downloadList.preview')">预览</a>
          </template>
        </span>
      </s-table>
    </a-card>
  </page-header-wrapper>
</template>

<script>
  import moment from 'moment'
  import { prefix } from '@/utils'
  import {
    Modal,
    message
  } from 'ant-design-vue';
  import {
    STable,
    Ellipsis
  } from '@/components'
  import {
    _getDocList,
    _delDoc
  } from '@/api/article'
  let timer
  const columns = [{
      title: '序号',
      scopedSlots: {
        customRender: 'serial'
      },
      align: 'center',
      width: '100px'
    },
    {
      title: '标题',
      dataIndex: 'title',
      scopedSlots: {
        customRender: 'gstitle'
      },
      align: 'center'
    },
    {
      title: '发布时间',
      dataIndex: 'create_time',
      scopedSlots: {
        customRender: 'time'
      },
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      width: '150px',
      scopedSlots: {
        customRender: 'action'
      },
      align: 'center'
    }
  ]

  export default {
    name: '',
    components: {
      STable,
      Ellipsis
    },
    data() {
      this.columns = columns
      return {
        prefix:prefix,
        visible: false,
        queryParam: {
          type: 1
        },
        loadData: parameter => {
          const requestParameters = Object.assign({}, parameter, this.queryParam)
          return _getDocList(requestParameters)
            .then(res => {
              return res.data
            })
        }
      }
    },
    methods: {
      add() { 
        this.$router.push({path:'/article/editDownLoad'})
      },
      edit(data) { 
        this.$router.push({path:'/article/editDownLoad?id='+data.id})
      },
      handleDel(data) { //删除
        let that = this
        Modal.confirm({
          content: '确认删除吗?',
          onOk() {
            let params = {
              id: data.id
            }
            _delDoc(params).then(res => {
              if (res.code == 200) {
                that.$message.success('删除成功')
                that.$refs.table.refresh(true)
              }else{
                that.$message.error(res.msg)
              }
            })
          }
        })
      },
      reset() { 
        this.$refs.table.refresh(true)
      },
      toView(data){
        window.open(this.prefix+data.content)
      }
    },
    filters: {
      time(t) {
        if (t) {
          return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
        } else {
          return t
        }
      }
    }
  }
</script>


<style scoped>
  .deliv_item {
    display: flex;
    align-items: center;
    margin-top: 36px;
  }

  .deliv_item_label {
    width: 120px;
  }

  .deliv_item_label i {
    font-style: normal;
    color: #FF3B30;
  }

  .modal_item {
    display: flex;
    margin-top: 10px;
  }

  .modal_item div:nth-child(1) {
    min-width: 112px;
  }

  .modal_item div:nth-child(2) img {
    margin-top: 5px;
  }
</style>
